
<div class="content content-form d-flex flex-column flex-column-fluid">

    <ng-container>
        <div class="mt-3">
           <h3 class="text-center">CHÀO MỪNG BẠN ĐÃ ĐẾN VỚI Phần mềm quản lý tổng thể Sở Y tế</h3>
           <div class="text-center">Xin vui lòng chọn một chức năng tại thanh menu phía trên để tiếp tục</div>
        </div>
    </ng-container>
</div>
