import { NgModule } from '@angular/core';
import { Routes, RouterModule, Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { AppRouteGuard } from './shared/common/auth/auth-route-guard';
import { AppComponent } from '@app/app.component';
import { NotificationsComponent } from '@app/shared/layout/notifications/notifications.component';
import { WelcomeComponent } from './shared/layout/welcome/welcome.component';

const routes: Routes = [
    {
        path: 'app',
        component: AppComponent,
        canActivate: [AppRouteGuard],
        canActivateChild: [AppRouteGuard],
        children: [
            {
                path: '',
                children: [
                    { path: 'notifications', component: NotificationsComponent },
                    { path: 'welcome', component: WelcomeComponent },
                    { path: '', redirectTo: '/app/main/dashboard', pathMatch: 'full' },
                ],
            },
            {
                path: 'main',
                loadChildren: () => import('./main/main.module').then(m => m.MainModule), // Lazy load main module
                data: { preload: true },
            },
            {
                path: 'admin',
                loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'gioi-thieu-chung',
                loadChildren: () => import('./pages/gioi-thieu-chung/gioi-thieu-chung.module').then(m => m.GioiThieuChungModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'danh-muc',
                loadChildren: () => import('./pages/danh-muc/danh-muc.module').then(m => m.DanhMucModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'he-thong',
                loadChildren: () => import('./pages/he-thong/he-thong.module').then(m => m.HeThongModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'ky-dien-tu',
                loadChildren: () => import('./pages/ky-dien-tu/ky-dien-tu.module').then(m => m.KyDienTuModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'quan-ly-lich-hop',
                loadChildren: () => import('./pages/quan-ly-lich-hop/quan-ly-lich-hop.module').then(m => m.QuanLyLichHopModule), // Lazy load admin module
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'quan-ly-cong-van',
                loadChildren: () => import('./pages/quan-ly-cong-van/quan-ly-cong-van.module').then(m => m.QuanLyCongVanModule),
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'quan-ly-du-lieu',
                loadChildren: () => import('./pages/quan-ly-du-lieu/quan-ly-du-lieu.module').then(m => m.QuanLyDuLieuModule),
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'tinh-toan-chi-so',
                loadChildren: () => import('./pages/tinh-toan-chi-so/tinh-toan-chi-so.module').then(m => m.TinhToanChiSoModule),
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            {
                path: 'ai-ho-tro',
                loadChildren: () => import('./pages/ai-ho-tro/ai-ho-tro.module').then(m => m.AiHoTroModule),
                data: { preload: true },
                canLoad: [AppRouteGuard],
            },
            { path: '**', redirectTo: '/app/main/dashboard' },
        ],
    },
];

@NgModule({
    imports: [
        //RouterModule.forChild(routes)]
        RouterModule.forRoot(routes, {
            useHash: false,
            // NOTICE: If you use `reuse-tab` component and turn on keepingScroll you can set to `disabled`
            // Pls refer to https://ng-alain.com/components/reuse-tab
            // scrollPositionRestoration: 'top',
            scrollPositionRestoration: 'disabled',
        }),
    ]
    ,
    exports: [RouterModule],
})
export class AppRoutingModule {
    constructor(
        private router: Router,
    ) {

    }
}
